
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const user = namespace("user");
const common_app = namespace("common_app");
@Component({})
export default class SwitchLang extends Vue {
  @common_app.State lang;
  @common_app.Mutation setLang;
  @common_app.Mutation setIsViewAlive;
  @user.Action queryUserMenus;
  options: any[] = [
    { label: "中文简体", value: "zh-CN" },
    { label: "English", value: "en" }
  ];
  langValue: string = "";
  created() {
    this.langValue = this.lang;
  }
  async handleLangChange(val) {
    this.langValue = val;
    this.setLang(val);
    if (this.$route.name !== "login") {
      await this.queryUserMenus({
        menuParent: "op_root",
        resourceClass: "3"
      });
    }
    this.setIsViewAlive(false);
    this.$nextTick(function() {
      this.setIsViewAlive(true);
    });
  }
}
