
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TopHeader from "./top-header.vue";
import SideNav from "./side-nav.vue";
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
@Component({
  components: { TopHeader, SideNav }
})
export default class Layout extends Vue {
  @user.Getter isLogin;
  @base.Action getNewOrderMessage;
  @app.State pbpx;
  created() {
    if (!this.isLogin) {
      this.$router.push("/login");
    }
  }
  destroyed() {
    // 销毁定时器
    this.timerId && clearInterval(this.timerId);
  }
  openMsgIds: any[] = [];
  timerId: number = 0;
}
