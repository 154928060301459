
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const app = namespace("app");
const user = namespace("user");
@Component({})
export default class SideNav extends Vue {
  @app.State sideNavIndex;
  @app.Mutation setSideNavIndex;
  @user.State menuList;
  showHoverNav: boolean = false;
  hoverIndex: number = 0;
  childrenNav: any[] = [];
  created() {
    this.matchSideActive();
  }
  meunKeyCodeMap: any = {
    monitor: "MC201000",
    customer: "MC200001",
    service: "MC202000",
    store: "MC203000",
    operation: "MC204000",
    learning: "MC205000",
    setting: "MC206000",
    statement: "MC207000",
    marketing: "MC006000",
    clue: "MC210001"
  };
  linkIndex() {
    this.$router.push("/");
  }
  matchSideActive() {
    let menuKey = this.$route.meta.menuKey;
    console.log("[ menuKey ]", menuKey);
    menuKey && this.setSideNavIndex(this.meunKeyCodeMap[menuKey]);
  }
  hoverOneLevel(i, nav) {
    if (nav.children) {
      this.showHoverNav = true;
      this.hoverIndex = i;
      this.childrenNav = nav.children;
    } else {
      this.showHoverNav = false;
      this.$router.push(nav.pageUrl);
    }
  }
  linkNav(nav) {
    if (!nav.children) {
      this.$router.push(nav.pageUrl);
    }
  }
  handleSelectMenu(index) {
    this.showHoverNav = false;
    this.$router.push(index);
  }
  @Watch("$route.path")
  watchRoute(val) {
    this.matchSideActive();
  }
}
