
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import langSwitch from "../layout/components/lang-switch.vue";
const user = namespace("user");
const app = namespace("app");
@Component({
  components: { langSwitch }
})
export default class TopHeader extends Vue {
  @user.State userData;
  @user.Action loginOut;
  @user.State selectedOrgCode;
  @user.Action setCurrentStore;
  @app.State orderPushState;
  @app.Mutation setOrderPushState;
  nameIsExpand: boolean = false;
  orgCode: string = "";
  logOut() {
    this.loginOut();
  }
  created() {
    this.orgCode = this.selectedOrgCode;
  }
  get name() {
    return this.userData.firstName;
    // (this.userData.firstName ? this.userData.firstName : "") +
    // (this.userData.lastName ? this.userData.lastName : "")
  }
  get role() {
    // return this.userData.roleList.map(item => item.roleName).join();
    return "";
  }
  async handleStoreChange(val) {
    await this.setCurrentStore({
      orgCode: val
    });
    this.$router.push(`/index?storeId=${val}`);
  }
}
